import getConfig from 'next/config'
import { useContext } from 'react'

import { useCurrencyRates } from '@nx/api/hooks'
import { useGetLaunchDarklyFlags } from '@nx/external/launch-darkly-client'
import {
  AuctionType,
  EAuctionType,
  ELotStatus,
  LotStatus,
} from '@nx/global-types'
import {
  currencyFormat,
  formatNumberWithCommas,
  roundToSignificantValue,
} from '@nx/helpers'
import { SmartlingAttributes } from '@nx/smartling'
import { getTranslate } from '@nx/translations'

import { UserContext } from '@web/context/UserInfoProvider'
import { NOT_DEFINED_CURRENCY } from '@web/helpers/enums'

import {
  PriceEstimate,
  PriceEstimateConverted,
  StyledParagraph,
  StyledPriceContainer,
} from './Price.styles'
import translate from './Price.translate.json'

const { publicRuntimeConfig } = getConfig()
const { text } = getTranslate(translate)

export function Price({
  auctionType,
  lotStatus,
  lNotPublishedResults,
  dHammerPrice,
  dHammerPremium,
  lExhibition,
  lReferDept,
  lWithoutReserve,
  dEstimateHigh,
  dEstimateLow,
  GBPLowEstimate,
  GBPHighEstimate,
  sCurrencySymbol,
  currencyISO,
}: PriceProps) {
  const { data: flags } = useGetLaunchDarklyFlags(
    publicRuntimeConfig.LAUNCHDARKLY_CLIENT_KEY
  )
  const { currencyConfig } = useContext(UserContext)
  const defaultCurrencyISO =
    currencyConfig?.userSelectedCurrency &&
    currencyConfig.userSelectedCurrency !== NOT_DEFINED_CURRENCY
      ? currencyConfig.userSelectedCurrency
      : undefined
  const currencyLocale = currencyConfig?.userLocaleCurrency

  const { data: currencyRates } = useCurrencyRates(
    publicRuntimeConfig.WEB_API_URL
  )

  const showDefaultCurrencyEstimate =
    !!GBPLowEstimate &&
    !!GBPHighEstimate &&
    lotStatus !== LotStatus.sold &&
    lotStatus !== LotStatus.unsold &&
    lotStatus !== LotStatus.withdrawn &&
    !!currencyRates &&
    !!defaultCurrencyISO &&
    !!currencyISO &&
    !!currencyLocale &&
    defaultCurrencyISO !== currencyISO &&
    defaultCurrencyISO !== NOT_DEFINED_CURRENCY &&
    !!currencyRates.rates[defaultCurrencyISO]

  return (
    <StyledPriceContainer>
      {lotStatus === LotStatus.sold && auctionType !== AuctionType.private ? (
        lNotPublishedResults ? (
          <StyledParagraph $level="M">{text('sold')}</StyledParagraph>
        ) : Math.round(dHammerPrice) !== Math.round(dHammerPremium) ? (
          <StyledParagraph
            $level="M"
            dangerouslySetInnerHTML={{
              __html: text('soldForPremium', {
                price: `${sCurrencySymbol}${formatNumberWithCommas(
                  dHammerPremium
                )}`,
              }),
            }}
          />
        ) : (
          <StyledParagraph
            $level="M"
            dangerouslySetInnerHTML={{
              __html: text('soldFor', {
                price: `${sCurrencySymbol}${formatNumberWithCommas(
                  dHammerPremium
                )}`,
              }),
            }}
          />
        )
      ) : auctionType === AuctionType.private ? (
        <StyledParagraph $level="M">
          {text('referToDepartment')}
        </StyledParagraph>
      ) : lExhibition ? null : lReferDept ? (
        <>
          {lWithoutReserve && (
            <StyledParagraph $level="M">
              {text('withoutReserve')}
            </StyledParagraph>
          )}
          <StyledParagraph $level="M">
            {text('referToDepartmentEstimate')}
          </StyledParagraph>
        </>
      ) : (
        !!(dEstimateHigh && dEstimateLow) && (
          <>
            {lWithoutReserve && (
              <StyledParagraph $level="M">
                {text('withoutReserve')}
              </StyledParagraph>
            )}
            <PriceEstimate
              $level="M"
              $unsold={
                lotStatus === LotStatus.unsold ||
                lotStatus === LotStatus.withoutResults
              }
              dangerouslySetInnerHTML={{
                __html: `${sCurrencySymbol}${formatNumberWithCommas(
                  dEstimateLow
                )} - ${sCurrencySymbol}${formatNumberWithCommas(
                  dEstimateHigh
                )}`,
              }}
            />

            {showDefaultCurrencyEstimate ? (
              <PriceEstimateConverted
                $level="M"
                {...SmartlingAttributes.noTranslate}
              >
                {flags?.feature_currency_localization ? (
                  currencyFormat({
                    value: roundToSignificantValue(
                      GBPLowEstimate *
                        currencyRates.rates[defaultCurrencyISO].rate
                    ),
                    iso: currencyISO,
                    locale: currencyLocale,
                    bonhamsCode:
                      currencyRates.rates[defaultCurrencyISO].bonhams_code,
                  })
                ) : (
                  <>
                    {currencyRates.rates[defaultCurrencyISO].bonhams_code}
                    {formatNumberWithCommas(
                      roundToSignificantValue(
                        GBPLowEstimate *
                          currencyRates.rates[defaultCurrencyISO].rate
                      )
                    )}
                  </>
                )}
                {' - '}
                {flags?.feature_currency_localization ? (
                  currencyFormat({
                    value: roundToSignificantValue(
                      GBPHighEstimate *
                        currencyRates.rates[defaultCurrencyISO].rate
                    ),
                    iso: currencyISO,
                    locale: currencyLocale,
                    bonhamsCode:
                      currencyRates.rates[defaultCurrencyISO].bonhams_code,
                  })
                ) : (
                  <>
                    {currencyRates.rates[defaultCurrencyISO].bonhams_code}
                    {formatNumberWithCommas(
                      roundToSignificantValue(
                        GBPHighEstimate *
                          currencyRates.rates[defaultCurrencyISO].rate
                      )
                    )}
                  </>
                )}
              </PriceEstimateConverted>
            ) : null}
          </>
        )
      )}
    </StyledPriceContainer>
  )
}

export interface PriceProps {
  auctionType: EAuctionType
  lotStatus: ELotStatus
  lNotPublishedResults: boolean
  dHammerPrice: number
  dHammerPremium: number
  lExhibition: boolean
  lReferDept: boolean
  lWithoutReserve: boolean
  dEstimateLow: number
  dEstimateHigh: number
  GBPLowEstimate: number
  GBPHighEstimate: number
  sCurrencySymbol: string
  currencyISO: string
}
